'use client';

import { FC, useMemo } from 'react';

import { Icon, toIconName } from '@lichtblick/icons';
import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { Modal } from '@lichtblick/photon/organisms/modal/modal';

import styles from './o-info-dialog.module.scss';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { ElementMappers, RichText } from '../../helpers/RichText';
import { OInfoDialogType } from '../../types/storyblok';
import { getColor } from '../../utils/colors';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';
import { MAnimatedIllustration } from '../MAnimatedIllustration/MAnimatedIllustration';

export type OInfoDialogProps = OInfoDialogType & {
  onSecondaryButtonClick: () => void;
  open?: boolean;
};

const MediaContent: FC<{ content: OInfoDialogProps['media'] }> = ({ content: contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} />;
    case 'a-video':
      return <AVideo {...content} />;
    case 'm-animated-illustration':
      return <MAnimatedIllustration {...content} />;
    default:
      assertExhaustive(content);
  }
};

const createElementMappers = (center: boolean): Partial<ElementMappers> => ({
  h3: ({ children }) => (
    <Headline align={center ? 'center' : undefined} className={styles.headline} renderAs={'div'} size="s">
      {children}
    </Headline>
  ),
  p: ({ children }) => (
    <Text align={center ? 'center' : undefined} className={styles.text}>
      {children}
    </Text>
  ),
  h6: ({ children }) => (
    <Headline align={center ? 'center' : undefined} className={styles.h6} renderAs={'div'} size="s">
      {children}
    </Headline>
  ),
});

export const OInfoDialog: FC<OInfoDialogProps> = ({
  ariaLabel,
  buttonText,
  headline,
  headlineIcon,
  headlineIconColor: shiftColor,
  isTextCentered = false,
  media,
  onSecondaryButtonClick,
  open,
  richText,
}) => {
  const elementMappers = useMemo(() => {
    return createElementMappers(isTextCentered);
  }, [isTextCentered]);

  return (
    <Modal
      aria-label={ariaLabel}
      footerContent={
        <TextCTA as="button" onClick={onSecondaryButtonClick}>
          {buttonText}
        </TextCTA>
      }
      isOpen={open}
      onPointerDownOutside={onSecondaryButtonClick}
      title={ariaLabel}
    >
      {Boolean(headline) && (
        <div className={styles.header}>
          <Headline align={isTextCentered ? 'center' : undefined} renderAs={'div'} size="s">
            {headline}
          </Headline>
          {headlineIcon && <Icon name={toIconName(headlineIcon) as any} shiftColor={getColor(shiftColor)} />}
        </div>
      )}
      {Boolean(richText) && <RichText elementMappers={elementMappers} richText={richText} />}
      {media?.length === 1 && <MediaContent content={media} />}
    </Modal>
  );
};
